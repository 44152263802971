<template>
<div>{{form}}</div>
</template>

<script>
import store from "@/store";
import {Emas} from "@/assets/js/Emas";

export default {
  name: "index",
  data() {
    return{
      form:{
        code:200,
        msg:'绿色地址'
      }
    }
  },
  mounted() {
    let userInfo = store.getters["user/userInfo"]
    let User_ID = userInfo.user_ID
    let Staff_Name = userInfo.staff_Name
    Emas(Staff_Name, User_ID,"/green", "绿色页面", "zzdzx.yy.gov.cn/green")
  },
}
</script>

<style scoped>

</style>